import { useProjectStore } from '@/Stores/ProjectStore'
import { usePage } from '@inertiajs/vue3'
import axios from 'axios'
import { defineStore } from 'pinia'
import { computed, type Ref, ref, unref } from 'vue'

type RoomData = App.Data.RoomData
type RoomMessageData = App.Data.RoomMessageData

export const useRoomStore = defineStore('Room', () => {
    const projectStore = useProjectStore()

    const { user } = usePage().props.auth

    const rooms: Ref<RoomData[]> = ref([])

    const unreadMsgCount = computed(() => {
        return rooms.value.reduce((acc, room) => {
            return acc + room.unread_msg_count
        }, 0)
    })

    const projectRooms = computed(() => {
        return rooms.value.filter(r => projectStore.active && r.project_id === projectStore.active.id)
    })

    const activeId = ref<number | null>(null)

    const hasActive = computed(() => Boolean(activeId.value))

    function setActive(roomId: number | null) {
        activeId.value = roomId
    }

    const active = computed(() => {
        return rooms.value.find(r => r.id === unref(activeId))
    })

    function getById(roomId: number) {
        return rooms.value.find(r => r.id === roomId)
    }

    function getProjectUnreadMsgCount(projectId: number) {
        return rooms.value.filter(r => r.project_id === projectId).reduce((acc, room) => {
            return acc + room.unread_msg_count
        }, 0)
    }

    function addMessage(message: RoomMessageData) {
        const room = rooms.value.find(r => r.id === message.room_id)
        if (room) {
            room.messages.push(message)
            room.lastMessage = message
        }
    }

    function getFirstIncomingUnreadMessage(room: RoomData) {
        return room.messages.filter(m => m.author.id !== user.id && (!room.last_read_msg_ulid || m.ulid > room.last_read_msg_ulid))[0]
    }

    function markActiveRoomAsRead() {
        const room = active.value
        if (room && room.messages.length) {
            const lastIncomingMessage = room.messages.filter(m => m.author.id !== user.id).slice(-1)[0]
            if (!lastIncomingMessage) {
                return
            }

            if (!room.last_read_msg_ulid || lastIncomingMessage.ulid > room.last_read_msg_ulid) {
                // set room_member last_read_msg_ulid
                axios.put(route('room.member.update_last_read_msg', { roomId: room.id, msgUlid: lastIncomingMessage.ulid }))
                    .then(() => room.last_read_msg_ulid = lastIncomingMessage.ulid)
            }

            room.unread_msg_count = 0
        }
    }

    // function $reset(projectRooms = []) {
    //     rooms.value = projectRooms
    //     activeId.value = null
    // }

    return {
        rooms,
        unreadMsgCount,
        projectRooms,
        activeId,
        active,
        hasActive,
        setActive,
        getById,
        getProjectUnreadMsgCount,
        addMessage,
        getFirstIncomingUnreadMessage,
        markActiveRoomAsRead,
        //$reset
    }
})

import { useDialogStore } from '@/Stores/DialogStore'
import { storeToRefs } from 'pinia'
import { computed, unref } from 'vue'

export function useDialog(id: string, defaultOpen: boolean = false) {
    const dialogStore = useDialogStore()
    const { dialogs} = storeToRefs(dialogStore)

    // register modal
    if (unref(dialogs)[id] === undefined) {
        unref(dialogs)[id] = {
            open: defaultOpen
        }
    }

    const isOpen = computed(() => {
        return unref(dialogs)[id]?.open ?? false
    })

    return {
        isOpen,
        getParams: (name: string | null = null) => name ? dialogStore.getParams(id)[name] ?? null : dialogStore.getParams(id),
        openDialog: (params: object | null = null) => {
            document.body.classList.add('overflow-hidden')
            unref(dialogs)[id] = {
                params,
                open: true
            }
        },
        closeDialog: function() {
            document.body.classList.remove('overflow-hidden')
            unref(dialogs)[id] = {
                open: false
            }
        }
    }
}

import { defineStore } from 'pinia'
import { ref, unref } from 'vue'

interface Dialog {
    open: boolean,
    params?: any
}
interface Dialogs {
    [key: string]: Dialog
}

export const useDialogStore = defineStore('Dialog', () => {
    const dialogs = ref<Dialogs>({})

    function isOpen(id: string) {
        return unref(dialogs)[id]?.open ?? false
    }

    function getParams(id: string) {
        return unref(dialogs)[id]?.params ?? {}
    }

    return {
        dialogs,
        isOpen,
        getParams
    }
})

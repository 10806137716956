import AppLayout from '@/Components/AppLayout.vue'
import { useUserEventsConnection } from '@/Composables/useUserEventsConnection'
import { CSRF_TOKEN } from '@/injectionKeys'
import resetStore from '@/Stores/Plugins/resetStore'
import { useProjectStore } from '@/Stores/ProjectStore'
import { useRoomStore } from '@/Stores/RoomStore'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue, { type BugsnagPluginVueResult } from '@bugsnag/plugin-vue'
import { createInertiaApp, usePage } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import mitt from 'mitt'
import { createPinia } from 'pinia'
import { type ComputedRef, computed, createApp, h, type DefineComponent, type ComponentInstance } from 'vue'
import VueTippy from 'vue-tippy'
import { ZiggyVue } from 'ziggy-js/dist/vue'
import '../css/app.css'
import 'tippy.js/dist/tippy.css'

// noinspection JSUnresolvedReference
const isDev = ['local', 'develoment'].includes(process.env.NODE_ENV ?? 'production')
if (!isDev) {
    // noinspection JSUnresolvedReference
    Bugsnag.start({
        apiKey: '9a2cc6adcf071f41658b4945b1d84d9b',
        plugins: [new BugsnagPluginVue()],
        appVersion: __buildDate__,
        releaseStage: process.env.NODE_ENV,
        enabledReleaseStages: [ 'production', 'staging' ],
        onError: function (event) {
            const auth = computed(() => usePage().props.auth)
            const user = auth.value?.user ?? false
            if (user) {
                event.setUser(user.id.toString(), user.email, user.full_name)
            }
        }
    })
}

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
    progress: {
        delay: 250,
    },
    resolve: (name: string) => {
        let page = resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue'))
        page.then((module: ComponentInstance<any>) => {
            module.default.layout = module.default.layout || AppLayout
        })
        return page
    },
    // resolve: (name: string) => {
    //     /**
    //      * eager
    //      */
    //     // const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
    //     // return pages[`./Pages/${name}.vue`]
    //     /**
    //      * lazy
    //      * @see https://stackoverflow.com/a/72867218/4005858
    //      */
    //     const pages = import.meta.glob('./Pages/**/*.vue')
    //     let page = pages[`./Pages/${name}.vue`]()
    //     page.then(module => {
    //         module.default.layout = module.default.layout || AppLayout
    //     })
    //
    //     return page
    // },
    setup({ el, App, props, plugin }) {
        const VueApp = createApp({ render: () => h(App, props) })

        if (!isDev) {
            VueApp.use(Bugsnag.getPlugin('vue') as BugsnagPluginVueResult)
        }

        const pinia = createPinia()
        pinia.use(resetStore)

        const csrfToken = <ComputedRef<string>>computed(() => usePage().props.csrf_token)
        VueApp.use(plugin)
            .use(ZiggyVue)
            .use(pinia)
            .use(VueTippy, {
                directive: "tippy",
                defaultProps: {
                    placement: 'bottom',
                    allowHTML: true,
                    delay: [500, null],
                },
            })
            .provide(CSRF_TOKEN, csrfToken)
            .provide('notifications', props.initialPage.props.notifications)
            .provide('auth', props.initialPage.props.auth)
            .provide('emitter', mitt())
            .mount(el)

        const projectStore = useProjectStore()
        // noinspection JSValidateTypes
        projectStore.projects = props.initialPage.props.projects
        const roomStore = useRoomStore()
        // noinspection JSValidateTypes
        roomStore.rooms = props.initialPage.props.rooms

        // open user connection / may be moved into AppLAyout ?
        const userId = props.initialPage.props.auth.user?.id

        const { openConnection } = useUserEventsConnection()
        // open new connection if project is not null and is not the same as the previous one
        if (userId) {
            // noinspection JSValidateTypes
            openConnection(userId)
        }
    },
})

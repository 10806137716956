<script setup lang="ts">
import RoomMessagePreview from '@/Shared/Room/RoomMessagePreview.vue'
import { formatTime } from '@/Utils/date'
import { UserIcon, UsersIcon, UserGroupIcon } from '@heroicons/vue/24/solid'
import { usePage } from '@inertiajs/vue3'
import { differenceInCalendarDays } from 'date-fns'
import { computed } from 'vue'
type RoomData = App.Data.RoomData

interface Props {
    room: RoomData
}
const { room } = defineProps<Props>()

defineEmits(['enter-room'])

const page = usePage()
const user = computed(() => page.props.auth.user);

function formatRoomDate(timestamp: string | number) {
    const diffInDays = differenceInCalendarDays(new Date(), new Date(timestamp))
    if (diffInDays === 0) {
        return formatTime(timestamp, 'HH:mm')
    }
    if (diffInDays === 1) {
        return 'hier'
    }

    if (diffInDays < 7) {
        return formatTime(timestamp, 'EEEE')
    }

    return formatTime(timestamp, 'dd/MM/yyyy')
}

const roomAvatar = computed(() => {
    const otherMembersCount = room.members.length - 1
    switch (otherMembersCount) {
        case 1:
            return UserIcon;
        case 2:
            return UsersIcon;
        default:
            return UserGroupIcon;
    }
})
</script>

<template>
    <li
        @click="$emit('enter-room', room.id)"
        class="flex h-18 items-center cursor-default"
    >
        <div class=" mr-3 aspect-1  shrink-0   ">
            <div class="bg-light-400 dark:bg-dark-600 rounded-full w-12 h-12 p-2 text-light-100 dark">
                <component :is="roomAvatar" class="w-full h-full" />
            </div>
        </div>
      <div class="flex-1">
          <div class=" flex flex-col overflow-x-hidden select-text">
              <div class="flex min-w-0 pr-0.5">
                  <h2 class="flex-1 text-sm/4 font-semibold truncate">{{ room.name }}</h2>
                  <span
                      class="shrink-0 pl-2 text-xs/4"
                      :class="[room.unread_msg_count > 0 ? 'text-blue-600' : 'text-light-500 dark:text-dark-400']"
                  >
                      {{ formatRoomDate(room.lastMessage?.created_at ?? room.created_at) }}
                  </span>
              </div>
              <div class="flex min-w-0 mt-1">
                  <RoomMessagePreview
                      :message="room.lastMessage"
                      :direction="room.lastMessage.author.id === user.id ? 'out' : 'in'"
                      :show-author="room.members.length > 2 || room.lastMessage.author.id === user.id"
                  />
                  <div>
                      <div v-if="room.unread_msg_count > 0"
                           class="flex min-w-[16px] px-1 h-4 items-center justify-center bg-blue-600 rounded-full text-[11px]/4 text-white font-medium">{{ room.unread_msg_count }}</div>
                  </div>
              </div>
          </div>

      </div>
    </li>
</template>

<script setup lang="ts">
import CustomTransition from '@/Components/CustomTransition.vue'
import { useProjectStore } from '@/Stores/ProjectStore'
import { PlusIcon as AddIcon, EnvelopeIcon as InviteIcon } from '@heroicons/vue/24/outline'
import RoomCreateForm from '@/Shared/Room/RoomCreateForm.vue'
import { Link, usePage } from '@inertiajs/vue3'
import { storeToRefs } from 'pinia'
import { computed, ref, unref } from 'vue'

const user = computed(() => usePage().props.auth.user)

const projectStore = useProjectStore()
const { active: project } = storeToRefs(projectStore)

const availableMembers = computed(() => unref(project)!.members.filter(m => m.user_id && m.user_id !== user.value.id))

const newRoom = ref(false)
</script>

<template>
    <div class="flex-1 flex flex-col items-center">
        <div class="flex-2 flex flex-col justify-start w-full px-4">
            <template v-if="availableMembers.length > 0">
                <CustomTransition name="slide-in-bottom" mode="out-in">
                    <RoomCreateForm
                        v-if="newRoom"
                        :available-members="availableMembers"
                        @cancel="newRoom = false"
                        class="mt-8 md:mt-16"
                    />
                    <div v-else class="flex flex-col items-center">
                        <p class="px-4 py-8 md:py-16 text-sm text-light-500 text-center">
                            Les rooms vous permettent d'échanger facilement avec les différents membres de votre projet en évitant les boucles emails.
                        </p>
                        <button
                            @click="newRoom = true"
                            class="flex items-center px-3 py-1.5 text-light-600 dark:text-light-400 rounded-md hover:bg-light-200 dark:hover:bg-dark-800 active:bg-light-300 dark:active:bg-dark-700"
                        >
                            <AddIcon class="w-5 h-5 stroke-2 text-action-600 -ml-1 mr-2" />
                            <span class="font-medium">Créer une room</span>
                        </button>
                    </div>
                </CustomTransition>
            </template>

            <div v-else class="flex flex-col items-center">
                <div class="px-4 py-8 md:py-16 space-y-6">
                    <p class="text-sm text-light-500 text-center">
                        Les rooms vous permettent d'échanger facilement avec les différents membres de votre projet en évitant les boucles emails.
                    </p>
                    <p class="text-sm text-light-500 text-center">
                        Pour commencer à en profiter, invitez d'autres personnes (collaborateurs, client ou prestataires) à vous rejoindre dans ce projet.
                    </p>
                </div>
                <Link
                    :href="route('project.members', { ulid: project!.ulid })"
                    class="flex items-center px-3 py-1.5 text-light-600 dark:text-dark-400 rounded-md hover:bg-light-200 dark:hover:bg-dark-800 active:bg-light-300 dark:active:bg-dark-700 transition-colors duration-300 ease-in-out"
                >
                    <InviteIcon class="w-5 h-5 stroke-2 text-action-600 -ml-1 mr-2" />
                    <span class="font-medium">Inviter d'autres personnes</span>
                </Link>
            </div>
        </div>

        <div class="flex-1 flex w-full pr-3 pb-4 items-end justify-end">
            <span class="hidden text-4xl font-extrabold text-blue-500/30">illustration</span>
        </div>
    </div>
</template>

/**
 * Reset a store to its initial state
 * @see https://dev.to/the_one/pinia-how-to-reset-stores-created-with-functionsetup-syntax-1b74
 */
import cloneDeep from 'lodash.clonedeep'

export default function resetStore({ store }) {
    const initialState = cloneDeep(store.$state)
    store.$reset = () => store.$patch(cloneDeep(initialState))
}

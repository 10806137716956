import { computed, ref, unref, watch } from 'vue'

export function useWebNotifications(params) {

    const isSupported = !!window && "Notification" in window

    const permission = ref(isSupported ? Notification.permission : null)

    const permissionDenied = computed(() => {
        return !isSupported || permission.value === 'denied'
    })

    const permissionGranted = computed(() => {
        return isSupported && permission.value === 'granted'
    })

    const shouldRequestPermission = ref(false)

    watch(shouldRequestPermission, ask => {
        if (ask && params?.auto) {
            if (confirm("Pour utiliser nos rooms, vous devez autoriser les notifications")) {
                // noinspection JSIgnoredPromiseFromCall
                ensurePermission()
            }
        }
    })

     const ensurePermission = async () => {
        if (!isSupported || permission.value !== 'default') {
            return
        }

        permission.value = await Notification.requestPermission()
        if (permission.value === 'granted') {
            shouldRequestPermission.value = false
            pendingNotifications.value.map(options => {
                send(options)
            })
        }
        if (permission.value === 'denied') {
            shouldRequestPermission.value = false
            pendingNotifications.value.length = 0
        }

        return permissionGranted.value
    }

    const pendingNotifications = ref([])

    function notify(options) {
        if (unref(permissionDenied)) {
            return
        }

        if (!permissionGranted.value) {
            pendingNotifications.value.push(options)
            shouldRequestPermission.value = true
            return
        }

        // finally send
        send(options)
    }

    function send(options) {
        const { title, onClick, ...remaining} = options
        const notification = new Notification(title ?? '', remaining)
        if (onClick) {
            notification.onclick = event => onClick(event, notification)
        }
    }

    return {
        isSupported,
        permission,
        shouldRequestPermission,
        pendingNotifications,
        notify
    }
}

import { useWebNotifications } from '@/Composables/useWebNotifications'
import { useProjectStore } from '@/Stores/ProjectStore'
import { useRoomStore } from '@/Stores/RoomStore'
import { router, usePage } from '@inertiajs/vue3'
import { ref } from 'vue'

const evtSource = ref(null)

/**
 * @param {int} userId
 */
const openConnection = function(userId) {
    // console.log('opening project %d connection', project.id)

    const url = new URL(import.meta.env.VITE_MERCURE_URL)
    url.searchParams.append('topic', `https://wiroom.io/user/${userId}`)

    evtSource.value = new EventSource(url)
    evtSource.value.onmessage = function(messageEvent) {
        const { event, data } = JSON.parse(messageEvent.data)
        const handler = messageHandlers[event] ?? false
        if (handler) {
            handler(data)
        }
    }
    evtSource.value.onopen = () => console.debug('EventSource: connection opened on user %d', userId)
    evtSource.value.onerror = () => console.warn('EventSource: connection error, will retry')
}

const closeConnection = function() {
    if (evtSource.value) {
        // console.log('closing project %d connection', project.id)
        evtSource.value.close()
    }
}

const messageHandlers = {
    'App\\Events\\RoomMessageCreated': handleRoomNewMessage,
}

function handleRoomNewMessage(data) {
    const roomStore = useRoomStore()
    const projectStore = useProjectStore()

    const { notify } = useWebNotifications({auto: true})
    const { message } = data

    roomStore.addMessage(message)
    const { user } = usePage().props.auth
    if (message.author.id !== user.id) {
        // need to check if room is already loaded, meaning user has access to it
        const currentRoom = roomStore.getById(message.room_id)
        if (currentRoom && currentRoom.id !== roomStore.activeId) {
            currentRoom.unread_msg_count++
            // if device is not mobile
            let isMobile = window.matchMedia("(any-pointer:coarse)").matches;
            // noinspection JSUnusedGlobalSymbols
            !isMobile && notify({
                title: `Nouveau message de ${message.author.first_name}`,
                body: message.content,
                renotify: true,
                tag: message.ulid,
                onClick: (event, notification) => {
                    if (projectStore.active.id !== currentRoom.project_id) {
                        router.visit(route('project.show', { project: projectStore.getById(currentRoom.project_id).ulid }))
                    }
                    notification.close()
                }
            })
        }
    }
}

export function useUserEventsConnection() {
    return {
        openConnection,
        closeConnection
    }
}

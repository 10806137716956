<script setup lang="ts">
import RoomActivity from '@/Shared/Room/RoomActivity.vue'
import RoomMessage from '@/Shared/Room/RoomMessage.vue'
import { formatTime, getDistance, getIsToday } from '@/Utils/date'
import { usePage } from '@inertiajs/vue3'
import { useParentElement, useScroll } from '@vueuse/core'
import { useSound } from '@vueuse/sound'
import messageOutSfx from 'sounds/notifications/long-pop.mp3'
import messageInSfx from 'sounds/notifications/soap-bubble-sound.mp3'
import { nextTick, onMounted, ref, watch } from 'vue'

type Room = App.Data.RoomData
type RoomMessage = App.Data.RoomMessageData

const { play: playMessageInSfx } = useSound(messageInSfx, {
    volume: 0.4,
    playbackRate: 1.25
})
const { play: playMessageOutSfx } = useSound(messageOutSfx, {
    volume: 0.5,
    playbackRate: 2.5
})

interface Props {
    room: Room
    firstIncomingUnreadMessage?: RoomMessage
}

const { room, firstIncomingUnreadMessage } = defineProps<Props>()

const page = usePage()

const { user } = page.props.auth

const membersCount = room.members.length
const unreadMsgCount = room.unread_msg_count
const lastReadMsgUlid = room.last_read_msg_ulid

// scroll management
const messagesListEl = ref<HTMLElement | null>(null)
const { arrivedState } = useScroll(useParentElement(), {
    offset: { bottom: 12 }
})

function messageDirection(authorId: number) {
    return authorId === user.id ? 'out' : 'in'
}

const firstUnreadMessageEl = ref<HTMLElement[] | null>(null)
onMounted(() => {
    if (firstUnreadMessageEl.value) {
        firstUnreadMessageEl.value[0].scrollIntoView({
            behavior: 'instant',
            block: 'center',
        })
    } else {
        messagesListEl.value?.scrollIntoView(false)
    }
})

watch(() => room.messages.slice(-1).at(0), (lastMessage) => {
    if (lastMessage!.author.id === user.id || arrivedState.bottom) {
        nextTick(() => {
            messagesListEl.value?.scrollIntoView({
                behavior: 'instant',
                block: 'end',
            })
        })
    }
    // play sound
    messageDirection(lastMessage!.author!.id) === 'out'
        ? playMessageOutSfx()
        : playMessageInSfx()
})

let lastDayDate = ''
function isNewDate(timestamp: string) {
    const date = new Date(timestamp)
    const dayDate = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    if (dayDate !== lastDayDate) {
        lastDayDate = dayDate
        lastAuthorId = null
        return true
    }
    return false
}

function formatMaybeRelativeDate(timestamp: string) {
    const duration = getDistance(timestamp)
    const days = duration.days as number
    const years = duration.years as number

    switch (true) {
        case years === 0 && days <= 1:
            return getIsToday(timestamp) ? "Aujourd'hui" : "Hier"
        case years === 0 && days < 6:
            return formatTime(timestamp, 'EEEE')
        case years === 0:
            return formatTime(timestamp, 'EEE d MMM')
        default:
            return formatTime(timestamp, 'EEE d MMM yyyy')
    }
}

let lastAuthorId: number | null = null
function isSameAuthor(authorId: number, update = true) {
    if (authorId !== lastAuthorId) {
        if (update) {
            lastAuthorId = authorId
        }
        return false
    }
    return true
}

const ROOM_MESSAGE = 1
</script>

<template>
    <section ref="messagesListEl" class="select-text px-2.5 pb-2">
        <template v-for="(message, index) in room.messages" :key="message.id">
            <div v-if="isNewDate(message.created_at)" class="mt-6 sticky z-10 top-1 flex justify-center">
                <span class="bg-light-100 dark:bg-dark-800 px-3 py-0.5 rounded-full shadow-px shadow-light-800/30 text-xs font-medium text-light-600 dark:text-dark-400">
                    {{ formatMaybeRelativeDate(message.created_at) }}
                </span>
            </div>
            <div
                role="row"
                :ref="message.ulid === firstIncomingUnreadMessage?.ulid ? 'firstUnreadMessageEl' : ''"
            >
                <RoomMessage
                    v-if="message.type === ROOM_MESSAGE"
                    :message="message"
                    :user-is-author="message.author.id === user.id"
                    :is-same-author="isSameAuthor(message.author.id)"
                    :show-incoming-message-author="membersCount > 2"
                />
                <RoomActivity
                    v-else
                    :message="message"
                    :user-is-author="message.author.id === user.id"
                />
            </div>
            <div
                v-if="unreadMsgCount && (message.ulid === lastReadMsgUlid && index < (room.messages.length - 1) || !lastReadMsgUlid && index === 0)"
                class="-mr-2 xl:mx-2 my-4 py-1 bg-yellow-400/40 dark:bg-yellow-400/80 text-yellow-950 rounded-sm text-sm uppercase text-center shadow-sm dark:shadow-none"
            >
                {{ unreadMsgCount > 1 ? `${unreadMsgCount} messages non lus` : '1 message non lu' }}
            </div>
        </template>
    </section>
</template>

<!--suppress CssUnusedSymbol -->
<style lang="postcss">
.room-view-bg {
    background-color: #f8f0eb;
    background-image: url("data:image/svg+xml,%3Csvg width='100' height='100' viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 18c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm48 25c3.866 0 7-3.134 7-7s-3.134-7-7-7-7 3.134-7 7 3.134 7 7 7zm-43-7c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm63 31c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM34 90c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zm56-76c1.657 0 3-1.343 3-3s-1.343-3-3-3-3 1.343-3 3 1.343 3 3 3zM12 86c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm28-65c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm23-11c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-6 60c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm29 22c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zM32 63c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm57-13c2.76 0 5-2.24 5-5s-2.24-5-5-5-5 2.24-5 5 2.24 5 5 5zm-9-21c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM60 91c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM35 41c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2zM12 60c1.105 0 2-.895 2-2s-.895-2-2-2-2 .895-2 2 .895 2 2 2z' fill='%23ffffff' fill-opacity='0.24' fill-rule='evenodd'/%3E%3C/svg%3E");
    @apply dark:bg-none dark:bg-transparent;
}
</style>

// noinspection JSUnresolvedReference

import { useDialog } from '@/Composables/useDialog'
import { useRoomStore } from '@/Stores/RoomStore'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'

type Project = App.Data.ProjectData

export const useProjectStore = defineStore('Project', () => {
    const projects = ref<Project[]>([])
    const active = ref<Project | null>(null)

    const roomStore = useRoomStore()

    // reset curent active room when changing project
    watch(active, (project, formerProject) => {
        if (formerProject && project && project.id !== formerProject.id) {
            roomStore.setActive(null)
        } else if (!project) {
            useDialog('rooms').closeDialog()
        }
    })

    function isActive(project: Project) {
        return active.value && active.value.ulid === project.ulid
    }

    function setActiveProject(project: Project | null) {
        active.value = project

    }

    function getById(id: number) {
        return projects.value.find(project => project.id === id)
    }

    function getByUlid(ulid: string): Project | null {
        return projects.value.find(project => project.ulid === ulid) || null
    }

    const numProjects = computed(() => {
        return projects.value.length
    })

    const recentProjects = computed(() => {
        return projects.value.slice(0, 5)
    })

    return {
        projects,
        active,
        numProjects,
        recentProjects,
        isActive,
        setActiveProject,
        getById,
        getByUlid,
    }
})

<script setup>
import { usePage } from '@inertiajs/vue3'
import { ulid } from 'ulid'
import { ref, watch } from 'vue'

const flashes = ref([])
watch(() => usePage().props.flash, (flash) => {
    for (const type in flash) {
        if (flash[type]) {
            const flashUlid = ulid()
            flashes.value.push({
                ulid: flashUlid,
                type,
                message: flash[type]
            })
            setTimeout(() => {
                flashes.value.splice(flashes.value.findIndex(f => f.ulid === flashUlid), 1)
            }, type === 'error' ? 6000 : 4000)
        }
    }
}, {deep: true})

</script>

<template>
    <TransitionGroup
        name="flash"
        tag="ul"
        class="flex flex-col items-center space-y-1.5"
        appear
    >
        <li
            v-for="flash in flashes"
            :key="flash.ulid"
        >
            <div
                class="w-fit mt-2 px-5 py-4 rounded text-sm pointer-events-auto"
                :class="{
                    'bg-rose-300/95 text-rose-800 font-medium': flash.type === 'error',
                    'bg-accent-300/95 text-accent-800 font-medium': flash.type === 'warning',
                    'bg-emerald-300/95 text-emerald-800 font-medium': flash.type === 'success',
                    'bg-dark-700/90 text-white': flash.type === 'message',
                }"
            >
                {{ flash.message }}
            </div>
        </li>
    </TransitionGroup>
</template>

<!--suppress CssUnusedSymbol -->
<style>
.flash-move,
.flash-enter-active,
.flash-leave-active {
    transition: all 0.5s ease;
}
.flash-enter-from,
.flash-leave-to {
    opacity: 0;
}
.flash-enter-from {
    transform: translateY(24px) scale(0.95);
}
.flash-leave-to {
    transform: translateY(12px) scale(0.97);
}
</style>

<script setup lang="ts">
import SimpleDialog from '@/Components/SimpleDialog.vue'
import { useDialog } from '@/Composables/useDialog'
import WaitingListRegisterForm from '@/Shared/WaitingList/WaitingListRegisterForm.vue'
import { DialogTitle } from '@headlessui/vue'
import { XMarkIcon as CloseIcon } from '@heroicons/vue/20/solid'
import { usePage } from '@inertiajs/vue3'

type User = App.Data.UserData
const user = usePage().props.auth.user as User

const {isOpen, openDialog, closeDialog} = useDialog('register-waiting-list')
</script>

<template>
    <slot name="newProjectButton" :onClick="openDialog" />
    <SimpleDialog
        :is-open="isOpen"
        :close-modal="closeDialog"
        size="md"
        :blur="true"
    >
        <div class="absolute right-0 top-0 hidden pr-2 pt-2 sm:block">
            <button @click="closeDialog()"
                    class="p-3 text-light-400 rounded-md hover:text-light-500">
                <CloseIcon class="w-6 h-6" />
            </button>
        </div>
        <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex h-12 w-12 p-2 flex-shrink-0 items-center justify-center rounded-full bg-light-100 sm:mx-0"
            >
                <img src="/img/notification-logo.png" alt="Wi">
            </div>
            <div class="mt-3 sm:ml-4 sm:mt-0 ">
                <DialogTitle as="h3" class="text-lg lg:text-xl font-semibold leading-6 text-center sm:text-left">
                    <span>Bêta privée</span>
                </DialogTitle>
                <div class="mt-2 space-y-2">
                    <p class="text-sm text-accent-600 font-medium">
                        Wiroom est pour l'instant en accès bêta privé.
                    </p>
                    <p class="text-sm text-light-500">
                        Pour <strong>demander un accès</strong> et créer vous aussi votre premier projet, remplissez le formulaire ci-dessous.
                    </p>
                </div>
                <WaitingListRegisterForm
                    :user="user"
                    class="mt-6"
                    @success="closeDialog()"
                />
            </div>
        </div>
    </SimpleDialog>
</template>

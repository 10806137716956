import type { InjectionKey, Ref } from 'vue'
import type { Emitter } from 'mitt'

export const CSRF_TOKEN: InjectionKey<Ref<string>> = Symbol('CSRF_TOKEN')

export type ValidationEventBus = {
    'change-request:select': App.Data.AssetChangeRequestData,
}
interface ValidationContext {
    eventBus: Emitter<ValidationEventBus>
    focusMode: Ref<boolean>
    annotationMode: Ref<boolean>
}
export const VALIDATION_VIEW_CONTEXT: InjectionKey<ValidationContext> = Symbol('Content validation context')

// former keys
export const FILE_ASSET_KEY = Symbol('FILE_ASSET')
export const IMAGE_RESIZER_KEY = Symbol('IMAGE_RESIZER')

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
    value?: string
    optional?: boolean | string
}>()

const optionalHint = computed(() => {
    if (typeof props.optional === 'string') {
        return props.optional
    }

    return 'facultatif'
})
</script>

<template>
    <label class="flex items-baseline justify-between">
        <span v-if="value" class="text-sm font-medium text-light-700 dark:text-dark-300">{{ value }}</span>
        <span v-else><slot /></span>

        <span v-if="optional" class="text-xs text-light-500 dark:text-dark-500">{{ optionalHint }}</span>
    </label>
</template>

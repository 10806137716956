<script setup lang="ts">
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
} from '@headlessui/vue'
import { computed } from 'vue'

interface Props {
    isOpen: boolean
    closeModal: Function
    size: 'fit' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
    blur?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    blur: false,
})

const sizeClass = computed(() => {
    switch (props.size) {
        case 'fit':
            return 'max-w-fit'
        case 'md':
            return 'max-w-md'
        case 'lg':
            return 'max-w-lg'
        case 'xl':
            return 'max-w-xl'
        case '2xl':
            return 'max-w-2xl'
        case '3xl':
            return 'max-w-3xl'
        case '4xl':
            return 'max-w-4xl'
    }
})
</script>

<template>
    <TransitionRoot appear :show="isOpen" as="template">
        <Dialog as="div" @close="() => closeModal()" class="relative z-40">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-light-900/20 dark:bg-light-100/20"
                     :class="blur ? 'backdrop-blur-sm backdrop-brightness-75' : ''" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center lg:items-start justify-center p-4 lg:pt-16 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-200 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-linear"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogPanel
                            class="w-full transform rounded-xl bg-white dark:bg-dark-900 p-4 sm:p-6 text-left align-middle shadow-xl dark:shadow-none transition-all"
                            :class="[sizeClass]"
                        >
                            <slot />
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>


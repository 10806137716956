<script setup lang="ts">
import CustomTransition from '@/Components/CustomTransition.vue'
import { useDialog } from '@/Composables/useDialog'
import RoomCreateForm from '@/Shared/Room/RoomCreateForm.vue'
import RoomListItem from '@/Shared/Room/RoomListItem.vue'
import EmptyStateAware from '@/Components/EmptyStateAware.vue'
import NoRoomsYet from '@/Shared/Room/NoRoomsYet.vue'
import { useProjectStore } from '@/Stores/ProjectStore'
import { useRoomStore } from '@/Stores/RoomStore'
import { ChatBubbleLeftRightIcon as RoomsIcon, XMarkIcon } from '@heroicons/vue/24/outline'
import { usePage } from '@inertiajs/vue3'
import { storeToRefs } from 'pinia'
import { computed, ref, unref } from 'vue'

const page = usePage()

const user = computed(() => page.props.auth.user)

const { closeDialog: closeRooms } = useDialog('rooms')

const roomStore = useRoomStore()
const { projectRooms } = storeToRefs(roomStore)
const projectStore = useProjectStore()
const { active: project } = storeToRefs(projectStore)

const availableMembers = computed(() => unref(project)!.members.filter(m => m.user_id && m.user_id !== user.value.id))

const newRoom = ref(false)
</script>

<template>
    <div class="relative flex-1 flex flex-col min-h-0">
        <header class="flex px-3 py-2.5 standalone:pt-[calc(env(safe-area-inset-top)+12px)] items-center">
            <RoomsIcon class="w-7 h-7" />
            <div class="px-2 font-medium leading-6 dark:text-dark-200">Rooms "{{ project!.name }}"</div>
            <div class="flex-1"></div>
            <button
                @click="closeRooms()"
                class="p-2 rounded-full text-light-500 hover:text-light-700 focus:outline-none focus:bg-light-200"
            >
                <span class="sr-only">Fermer</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
            </button>
        </header>

        <EmptyStateAware :is-empty="!projectRooms.length">
            <template #empty>
                <NoRoomsYet />
            </template>

            <CustomTransition name="slide-in-bottom" mode="out-in">
                <div v-if="newRoom" class="overflow-y-auto px-3">

                <RoomCreateForm

                    :available-members="availableMembers"
                    @cancel="newRoom = false"
                    @success="newRoom = false"
                />
                </div>
                <div v-else class="overflow-y-auto">
                    <form class="pb-2 hidden">
                        <input type="search" class="w-full bg-light-50 py-1.5 border-0 ring-1 ring-light-950/10 shadow-sm focus:outline-none focus-visible:ring-2 rounded-md" placeholder="Recherche">
                    </form>

                    <div class="flex items-center justify-end px-3 pt-4 pb-2">
                        <button @click="newRoom = true" class="text-action-600 dark:text-action-500 dark:font-medium">Nouvelle room</button>
                    </div>

                    <ol class="border-t border-light-200 dark:border-dark-800">
                        <RoomListItem
                            v-for="room in projectRooms"
                            :key="room.id"
                            :room="room"
                            @enter-room="roomStore.setActive($event)"
                            class="px-2.5"
                        />
                    </ol>
                </div>
            </CustomTransition>
        </EmptyStateAware>
    </div>
</template>

<script setup>
import RoomMessagesList from '@/Shared/Room/RoomMessagesList.vue'
import RoomViewFooter from '@/Shared/Room/RoomViewFooter.vue'
import RoomViewHeader from '@/Shared/Room/RoomViewHeader.vue'
import { useRoomStore } from '@/Stores/RoomStore'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

const roomStore = useRoomStore()
const { active: room } = storeToRefs(roomStore)

onMounted(() => {
    roomStore.markActiveRoomAsRead()
})

function closeRoom() {
    roomStore.setActive(null)
}
</script>

<template>
    <div class="flex-1 flex flex-col min-h-0 bg-accent-900/5 lg:bg-transparent">
        <RoomViewHeader :close="closeRoom" />
        <div class="room-view-bg sm:!bg-none sm:!bg-transparent flex-1 flex flex-col overflow-x-hidden overflow-y-scroll">
            <div class="lg:flex-1"></div>
            <RoomMessagesList :room="room" :first-incoming-unread-message="roomStore.getFirstIncomingUnreadMessage(room)" />
        </div>
        <RoomViewFooter :room="room" />
    </div>
</template>

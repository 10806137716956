<script setup>
import CustomTransition from '@/Components/CustomTransition.vue'
import UnreadMessagesCounter from '@/Components/UnreadMessagesCounter.vue'
import { useAuthorisation } from '@/Composables/useAuthorisation'
import { useDialog } from '@/Composables/useDialog'
import Profile from '@/Shared/App/Profile.vue'
import CompanyLogo from '@/Shared/CompanyLogo.vue'
import ProjectEditSheet from '@/Shared/Project/ProjectEditSheet.vue'
import RegisterWaitingListModal from '@/Shared/WaitingList/WaitingListRegisterModal.vue'
import { useProjectStore } from '@/Stores/ProjectStore'
import { useRoomStore } from '@/Stores/RoomStore'
import { MenuButton } from '@headlessui/vue'
import {
    Cog6ToothIcon as ProjectSettingsIcon,
    ChevronRightIcon,
    EnvelopeOpenIcon as InvitedIcon,
    PlusIcon,
    UserCircleIcon,
} from '@heroicons/vue/20/solid'
import { Link, usePage } from '@inertiajs/vue3'
import { useScroll } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const roomStore = useRoomStore()
const projectStore = useProjectStore()
const { projects } = storeToRefs(projectStore)

const sortedProjects = computed(() => {
    return projects.value.sort((a, b) => {
        // sort projects by client_name then by project name
        return (a.client_name ?? 'ZZZ').localeCompare(b.client_name ?? 'ZZZ') || a.name.localeCompare(b.name)
    })
})
const clientProjects = computed(() => {
    return sortedProjects.value.reduce((acc, project) => {
        const clientName = project.client_name ? project.client_name : ''
        if (typeof acc[clientName] === 'undefined') {
            acc[clientName] = []
        }
        acc[clientName].push(project)

        return acc
    }, {})
})

const { openDialog: openProjectSheet, closeDialog: closeProjectSheet } = useDialog('project-sheet')

const scrollEl = ref(null)
const { arrivedState } = useScroll(scrollEl, {
    behavior: 'smooth',
    offset: { top: 4, bottom: 4 },
})

const page = usePage()
const user = computed(() => page.props.auth.user)

const { userCan } = useAuthorisation()
</script>

<template>
    <aside class="hidden lg:flex lg:flex-col shrink-0 w-64 rounded-bl-lg">
        <div class="px-4 py-1.5">
            <Link href="/" class="inline-flex py-1.5" aria-label="Retour tableau de bord">
                <CompanyLogo class="h-12" />
            </Link>
        </div>

        <!-- 88px + n * 40 - 10 -->
        <div class="flex flex-col min-h-0 mt-4">
            <nav class="flex-1 min-h-0 flex flex-col">
                <div class="flex items-center justify-between gap-4 mx-4 pt-3 pb-2">
                    <h2 class="text-sm text-light-600 font-medium">
                        <Link :href="route('projects.list')">
                            Projets
                        </Link>
                    </h2>
                </div>
                <div class="relative flex-1 flex flex-col min-h-0">
                    <CustomTransition>
                        <div v-show="sortedProjects.length > 3 && !arrivedState.top"
                             class="absolute z-10 top-0 inset-x-0 h-8 pointer-events-none bg-gradient-to-b from-light-100/90 to-light-100/10 dark:from-dark-950/80 dark:to-dark-950/20"></div>
                    </CustomTransition>
                    <CustomTransition>
                        <div v-show="sortedProjects.length > 3 && !arrivedState.bottom"
                             class="absolute z-10 bottom-0 inset-x-0 h-8 pointer-events-none bg-gradient-to-t from-light-100/90 to-light-100/10 dark:from-dark-950/80 dark:to-dark-950/20"></div>
                    </CustomTransition>

                    <div class="flex-1 min-h-0 overflow-y-scroll" ref="scrollEl">
                        <ul class="pr-3">
                            <li v-for="(projects, clientName) in clientProjects" :key="clientName"
                                class="relative"
                            >
                                <div v-if="clientName"
                                     class="absolute w-px bg-light-300 dark:bg-dark-700 top-10 bottom-3.5 my-px left-3 ml-1.5"></div>
                                <div v-else class="h-px ml-3 my-1.5 bg-light-200 dark:bg-dark-800"></div>

                                <div v-if="clientName"
                                     class="pl-4 pr-2 pt-2.5 pb-0.5 text-xs font-medium text-light-500 truncate">
                                    {{ clientName }}
                                </div>
                                <ul>
                                    <li v-for="project in projects" :key="project.id"
                                        class="py-px"
                                    >
                                        <Link :href="route('project.show', { project: project.ulid })"
                                              @click="closeProjectSheet"
                                              class="group flex items-center pr-2 py-2 text-sm font-medium rounded-r-2xl transition-colors duration-200 ease-in-out"
                                              :class="[
                                                  projectStore.isActive(project) ? 'bg-light-300/80 dark:bg-dark-800 font-semibold' : 'hover:bg-light-200 dark:hover:bg-dark-900 text-light-700 dark:text-dark-400',
                                                  clientName ? 'pl-8' : 'pl-4',
                                              ]"
                                              title="Voir le projet"
                                        >
                                            <span class="flex-1 min-w-0 flex items-center">
                                                <span class="truncate">{{ project.name }}</span>
                                                <UnreadMessagesCounter
                                                        :count="roomStore.getProjectUnreadMsgCount(project.id)" />
                                                <!--suppress VueUnrecognizedDirective -->
                                                <span v-tippy content="Invité">
                                                    <InvitedIcon v-if="project.owner_id !== user.id"
                                                                 class="ml-1 w-3 h-3 text-light-400" />
                                                </span>

                                            </span>
                                            <button @click.prevent.stop="openProjectSheet({ulid: project.ulid})"
                                                    class="p-1 hover:shadow rounded-full text-xs opacity-0 group-hover:opacity-100 focus:outline-none focus-visible:opacity-100 focus-visible:shadow transition duration-200"
                                                    title="Modifier le projet"
                                            >
                                                <ProjectSettingsIcon class="w-3.5 h-3.5 text-light-400" />
                                            </button>
                                            <ChevronRightIcon class="w-5 h-5"
                                                              :class="[projectStore.isActive(project) ? 'text-black' : 'text-light-600 opacity-0 group-hover:opacity-100 transition duration-200']" />
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <ProjectEditSheet v-if="userCan('create_project')">
                <template #newProjectButton="{ onClick }">
                    <div class="px-3 py-2">
                        <button
                                @click="onClick"
                                class="flex items-center px-1 py-1.5 dark:p-1 md:py-0.5 rounded-full text-accent-700 dark:text-accent-600
                            focus-visible:bg-white active:bg-white active:text-accent-900 focus:outline-none active:ring-2 focus-visible:ring-1 ring-accent-600 ring-offset-2"
                        >
                            <PlusIcon class="w-5 h-5 -ml-1 mr-0.5" />
                            <span class="text-sm font-semibold text-accent-700 dark:text-accent-600">Ajouter un projet</span>
                        </button>
                    </div>
                </template>
            </ProjectEditSheet>
            <RegisterWaitingListModal v-else>
                <template #newProjectButton="{ onClick }">
                    <div class="px-3 py-2">
                        <button
                                @click="onClick"
                                class="flex items-center px-1 py-1.5 dark:p-1 md:py-0.5 rounded-full text-accent-700 dark:text-accent-600
                            focus-visible:bg-white active:bg-white active:text-accent-900 focus:outline-none active:ring-2 focus-visible:ring-1 ring-accent-600 ring-offset-2"
                        >
                            <PlusIcon class="w-5 h-5 -ml-1 mr-0.5" />
                            <span class="text-sm font-semibold text-accent-700 dark:text-accent-600">Ajouter un projet</span>
                        </button>
                    </div>
                </template>
            </RegisterWaitingListModal>
        </div>
        <div class="flex-1"></div>
        <div class="bg-white/50 dark:bg-dark-900 mx-2.5 px-3 py-4 rounded-md shadow shadow-light-200 dark:shadow-none space-y-3 text-sm text-light-500">
            <p>Une question, une suggestion, un bug à nous remonter&nbsp;?</p>
            <div class="flex gap-x-1 items-center">
                <span class="text-lg">👉</span>
                <a href="mailto:hello@wiroom.io"
                   class="font-medium text-light-600 dark:text-dark-400 hover:underline"
                >hello@wiroom.io</a>
            </div>
            <p>On est aussi à votre dispo pour une démo rapide de 15 min + Q&R</p>
            <div class="flex gap-x-1 items-center">
                <span class="text-lg">🧑🏽‍💻</span>
                <a href="https://calendly.com/olivier-wiroom/30min"
                   target="_blank"
                   class="font-medium text-light-600 dark:text-dark-400 hover:underline"
                >Réserver une démo</a>
            </div>
        </div>
        <div class="py-4 text-sm px-3">
            <Profile direction="top-right" class="!ml-0">
                <template #menuButton>
                    <MenuButton class="group flex space-x-1.5 p-1 pr-4 max-w-xs items-center rounded-full text-sm
                        dark:bg-dark-800 text-gray-400 dark:text-dark-500 hover:text-gray-500 dark:hover:text-dark-400 active:text-gray-700 dark:active:text-dark-300
                        dark:focus:outline-none dark:focus:ring-2 ring-dark-600
                    ">
                        <UserCircleIcon class="w-8 h-8" aria-hidden="true" />
                        <span class="text-base font-medium text-light-800 dark:text-dark-400 dark:group-hover:text-dark-300">{{
                                user.first_name
                            }}</span>
                    </MenuButton>
                </template>
            </Profile>
        </div>
    </aside>
</template>

<script setup lang="ts">
import AppSidebar from '@/Components/AppSidebar.vue'
import { useDialog } from '@/Composables/useDialog'
import FlashMessages from '@/Shared/App/FlashMessages.vue'
import RoomsDialog from '@/Shared/App/RoomsDialog.vue'
import { provide } from 'vue'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

const breakpoints = useBreakpoints(breakpointsTailwind)
provide('screenIs', {
    xs: breakpoints.smaller('sm'),
    sm: breakpoints.between('sm', 'md'),
    mobile: breakpoints.smaller('md'),
    md: breakpoints.between('md', 'lg'),
    lg: breakpoints.between('lg', 'xl'),
    lgUp: breakpoints.greaterOrEqual('lg'),
    xl: breakpoints.between('xl', '2xl'),
    xxl: breakpoints['2xl'],
})

const { isOpen: roomsOpen } = useDialog('rooms')
</script>

<template>
    <!-- !! important: only set min-h-0 on desktop screen to allow page overflow-y on mobile/tablet !! -->
    <div
        data-component="AppLayout"
        class="lg:min-h-0 md:min-w-0 flex-1 flex flex-col md:flex-row"
    >
        <AppSidebar />
        <div class="relative standalone:lg:pt-2 min-h-0 md:min-w-0 flex-1 flex flex-col transition-all duration-200 ease-in-out"
             :class="roomsOpen ? 'xl:pr-[369px]' : 'pr-0'"
        >
            <slot />
            <Transition
                enter-active-class="transition transform origin-right duration-200 md:duration-300 ease-in-out"
                enter-from-class="opacity-50 translate-x-full"
                enter-to-class="translate-x-0"
                leave-active-class="transition transform origin-right duration-200 md:duration-100 ease-in-out"
                leave-from-class="translate-x-0"
                leave-to-class="translate-x-full opacity-50"
            >
                <RoomsDialog
                    v-if="roomsOpen"
                    class="fixed inset-y-0 right-0 w-full sm:w-92 z-40"
                />
            </Transition>
            <div
                class="fixed z-50 bottom-14 md:bottom-10 lg:bottom-0 inset-x-2 lg:left-64 flex items-end justify-center py-4 pointer-events-none"
                :class="[roomsOpen ? 'lg:right-[370px]' : '']"
            >
                <FlashMessages />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import UserAvatar from '@/Components/UserAvatar.vue'
import { formatTime } from '@/Utils/date'

type Message = App.Data.RoomMessageData

interface Props {
    message: Message
    userIsAuthor: boolean
    isSameAuthor: boolean
    showIncomingMessageAuthor: boolean
}

defineProps<Props>()

// process message content
// 1. replace links with <a> tags
function processMessageContent(content: string): string {
    return content.replace(
        /((?:https?:\/\/|www\.)[^\s<]+[^<.,:;"')\]\s])/g,
        (url) => `<a href="${url}" class="underline" target="_blank" rel="noopener noreferrer">${url}</a>`
    )
}
</script>

<template>
    <div class="msg-direction flex flex-col pr-3"
         :class="{
            'items-end': userIsAuthor,
            'items-start': !userIsAuthor,
            'mt-1': isSameAuthor,
            'mt-3': !isSameAuthor,
            'pl-10': showIncomingMessageAuthor,
            'pl-3': !showIncomingMessageAuthor,
        }"
    >
        <article
            data-component="MsgContainer"
            class="relative flex-none max-w-[85%] lg:text-sm select-text"

        >
            <span
                data-component="MsgTail"
                v-show="!isSameAuthor"
                class="absolute top-2 border-[7px] border-transparent"
                :class="[!userIsAuthor ? '-left-3.5 border-r-white dark:border-r-dark-700' : '-right-3.5 border-l-emerald-200/75 dark:border-l-emerald-900']"
            ></span>
            <UserAvatar
                v-if="showIncomingMessageAuthor && !userIsAuthor && !isSameAuthor"
                as="button"
                :user="message.author"
                class="absolute w-7 h-7 -left-10 top-0.5"
            />

            <div
                class="rounded-lg shadow-px"
                :class="{
                    'bg-emerald-200/75 dark:bg-emerald-800 text-emerald-950/95 dark:text-white shadow-light-400/60': userIsAuthor,
                    'bg-white dark:bg-dark-700 dark:text-dark-100 ring-light-900/5 shadow-light-400/30 dark:shadow-none': !userIsAuthor,
                }"
            >
                <div class="pl-2.5 pr-2 py-1.5">
                    <div
                        data-component="UserName"
                        v-if="showIncomingMessageAuthor && !userIsAuthor && !isSameAuthor"
                        class="inline-flex max-w-full font-semibold text-cyan-500/90 dark:text-cyan-400"
                    >
                        <span class="truncate">{{ message.author.full_name }}</span>
                    </div>

                    <div class="whitespace-pre-wrap break-words">
                        <span class="dark:font-medium dark:text-white" v-html="processMessageContent(message.content)"></span>
                        <span>
                            <span class="inline-flex px-1 text-[11px]/4 h-0 align-middle invisible">
                                <span class="grow-0">{{ formatTime(message.created_at, 'HH:mm') }}</span>
                            </span>
                        </span>
                    </div>
                    <div
                        class="relative z-10 float-right ml-1 -mt-3 -mb-1"
                    >
                        <div class="flex h-4 items-center text-[11px]/4 text-light-600 dark:text-dark-400 whitespace-nowrap">
                            <span class="inline-block align-top">{{ formatTime(message.created_at, 'HH:mm') }}</span>
                        </div>

                    </div>
                </div>
            </div>
        </article>
    </div>
</template>

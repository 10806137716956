<script setup>
import { Link, router, usePage } from '@inertiajs/vue3'
import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue'
import {
    UserCircleIcon,
} from '@heroicons/vue/20/solid'
import { getActivePinia } from 'pinia'
defineProps({
    direction: {
        type: String,
        default: 'bottom-left',
    },
})

const user = usePage().props.auth.user
const isLocal = process.env.NODE_ENV === 'local'

function resetStores() {
    const pinia = getActivePinia()
    pinia._s.forEach((store) => {
        try {
            store.$reset()
        } catch (e) {
            console.log(e);
        }
    })
}
function logout() {
    router.post(route('logout'), {}, {
        preserveState: false,
        onSuccess: () => {
            resetStores()
        },
    })
}
</script>

<template>
    <Menu as="div" class="relative">
        <slot name="menuButton">
            <MenuButton
                    class="flex max-w-xs items-center rounded-full dark:bg-dark-900 text-sm text-gray-400 dark:text-dark-500 hover:text-gray-500 dark:hover:text-dark-400 active:text-gray-700 dark:active:text-dark-300 p-1 dark:lg:hover:bg-dark-800">
                <UserCircleIcon class="w-7 h-7" aria-hidden="true" />
                <span class="sr-only">Ouvrir le menu utilisateur</span>
            </MenuButton>
        </slot>
        <transition enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
            <MenuItems
                    class="absolute z-10 w-56 rounded-md bg-white dark:bg-zinc-900 text-sm shadow-md divide-y divide-light-100 dark:divide-dark-700 ring-1 ring-black dark:ring-zinc-600 ring-opacity-5 focus:outline-none -mr-1.5"
                    :class="{
                            'right-0 mt-2 top-full origin-top-right': direction === 'bottom-left',
                            'left-0 mb-2 bottom-full origin-bottom-left': direction === 'top-right',
                            'right-0 mb-2 bottom-full origin-bottom-right': direction === 'top-left',
                       }"
            >
                <p class="truncate px-3.5 py-3" role="none">
                    <span class="block text-xs text-gray-500" role="none">Connecté en tant que</span>
                    <span class="mt-0.5 font-semibold" role="none">{{ user.email }}</span>
                </p>
                <div class="py-1.5" role="none" v-if="isLocal">
                    <MenuItem v-slot="{ active }">
                        <Link :href="route('changelog')"
                              :class="[active ? 'bg-light-100 dark:bg-dark-800' : '', 'block px-3.5 py-1.5']">Changelog
                        </Link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <a href="#" :class="[active ? 'bg-light-100 dark:bg-dark-800' : '', 'block px-3.5 py-1.5']">Support</a>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <Link :href="route('admin.changelog')"
                              :class="[active ? 'bg-light-100 dark:bg-dark-800' : '', 'block px-3.5 py-1.5']">Git log
                        </Link>
                    </MenuItem>
                </div>
                <div class="py-1.5" role="none">
                    <MenuItem v-slot="{ active }">
                        <Link :href="route('account.profile.edit')"
                              :class="[active ? 'bg-light-100 dark:bg-dark-800' : '', 'block px-3.5 py-1.5']">Mon profil
                        </Link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                        <button
                            @click="logout"
                            :class="[active ? 'bg-light-100 dark:bg-dark-800' : '', 'block w-full text-left px-3.5 py-1.5']"
                        >
                            Déconnexion
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

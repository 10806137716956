<script setup lang="ts">
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon, CheckCircleIcon as CheckedIcon, TrashIcon } from '@heroicons/vue/20/solid'
import { computed, toRef } from 'vue'

const props = withDefaults(defineProps<{
    role: string
    placeholder?: string
    canRemoveUser?: boolean
}>(), {
    role: '',
    canRemoveUser: false
})

defineEmits(['update:role', 'remove-member'])

const role = toRef(props, 'role')

const roles = [
    {
        name: 'Contributeur',
        description: 'Peut ajouter des contenus au projet et envoyer de nouvelles versions.',
        value: 'Creative',
    },
    {
        name: 'Client',
        description: 'Peut faire des demandes de modification et les valider.',
        value: 'Client',
    },
    {
        name: 'Agence / Cabinet',
        description: 'Peut agir comme un Client ou un Contributeur.',
        value: 'Agency',
    },
]

const roleName = computed(() => {
    return roles.find(r => r.value === role.value)?.name ?? props.placeholder ?? 'Rôle'
})
</script>

<template>
    <Menu as="div" class="relative text-sm text-light-800 dark:text-dark-400" v-slot="{ open }">
        <MenuButton
                class="flex items-center justify-between w-full text-left bg-light-200/50 dark:bg-dark-400/5 rounded ring-1 ring-light-900/10 dark:ring-white/10 px-3 py-1.5 md:px-4 md:py-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-action-700 focus-visible:ring-opacity-75"
                :class="open ? '' : 'lg:hover:bg-light-200/75 dark:lg:hover:bg-dark-800'"
        >
            <span>{{ roleName }}</span>
            <ChevronDownIcon
                    class="ml-2 -mr-1 h-5 w-5 text-light-500 transition duration-150 ease-in-out group-hover:text-opacity-80"
                    :class="open ? '' : 'text-opacity-70'"
                    aria-hidden="true"
            />
        </MenuButton>
        <Transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
        >
            <MenuItems
                class="absolute z-10 left-0 md:top-0 mt-2 md:mt-0 w-64 origin-top-left rounded-md bg-white dark:bg-dark-800 shadow-md dark:shadow-none shadow-light-300 ring-1 ring-black/5 dark:ring-dark-700 focus:outline-none divide-y divide-light-300 dark:divide-dark-700"
            >
                <div class="p-1 space-y-0.5">
                    <MenuItem
                        v-for="_role in roles"
                        :key="_role.name"
                        v-slot="{ active }"
                        class="px-1 py-0.5"
                    >
                        <button
                            type="button"
                            class="relative flex flex-col w-full rounded px-2 py-2 text-left"
                            :class="{
                                'bg-light-100 dark:bg-dark-900': _role.value === role && !active,
                                'bg-light-200/80 dark:bg-dark-900/80': active,
                            }"
                            @click="$emit('update:role', _role.value)"
                        >
                            <CheckedIcon v-show="_role.value === role" class="absolute w-5 h-5 top-2 right-2 text-action-600" />
                            <span class="text-sm/6 font-semibold">{{ _role.name }}</span>
                            <span class="text-xs">{{ _role.description }}</span>
                        </button>
                    </MenuItem>
                </div>
                <div v-if="canRemoveUser" class="p-1">
                    <MenuItem
                        v-slot="{ active }"
                    >
                        <button
                            class="flex items-center w-full rounded p-2 gap-x-1.5 "
                            :class="[active ? 'bg-light-200/80 dark:bg-dark-900/80' : '']"
                            @click="$emit('remove-member')"
                        >
                            <TrashIcon class="w-4 h-4 text-light-400" />
                            <span>Retirer ce membre du projet</span>
                        </button>
                    </MenuItem>
                </div>
            </MenuItems>
        </Transition>
    </Menu>
</template>

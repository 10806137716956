<script setup lang="ts">
import { useDialog } from '@/Composables/useDialog'
import { useProjectStore } from '@/Stores/ProjectStore'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XMarkIcon as CloseIcon, TrashIcon } from '@heroicons/vue/20/solid'
import { router, useForm } from '@inertiajs/vue3'
import { computed, ref, watch } from 'vue'
import InputLabel from '@/Components/InputLabel.vue'
import MemberRoleSelector from '@/Shared/Project/MemberRoleSelector.vue'
import InputError from '@/Components/InputError.vue'
import TextInput from '@/Components/TextInput.vue'

withDefaults(defineProps<{
    detent?: 'medium' | 'large'
}>(), {
    detent: 'medium'
})

const { isOpen, getParams, openDialog, closeDialog } = useDialog('project-sheet')
function openNewProjectSheet() {
    openDialog({
        ulid: null
    })
}

// initial focus field
const inputName = ref(null)


// form
const editMode = computed(() => {
    return !!getParams()['ulid']
})

const projectStore = useProjectStore()
const form = useForm({
    name: '',
    member_role: '',
    client_name: ''
})
// set form values
watch([() => isOpen, () => getParams()], ([isOpen, params]) => {
    if (!isOpen) return

    if (params.ulid) {
        const project = projectStore.getByUlid(params.ulid)
        form.client_name = project?.client_name as string
        form.name = project?.name as string
    } else if (params.ulid !== undefined) {
        form.client_name = ''
        form.name = ''
    }
})
function submitForm() {
    const { ulid } = getParams()
    const url = ulid
        ? route('project.update', {ulid})
        : route('projects.store')

    form.submit(ulid ? 'put' : 'post', url,{
        preserveScroll: true,
        preserveState: false,
        onSuccess: function(page) {
            const { projects } = page.props
            projectStore.projects = projects
            form.reset()
            closeDialog()
        }
    })
}

function deleteProject() {
    const { ulid } = getParams()
    // ask user to confirm deletion
    const project = projectStore.getByUlid(ulid)
    if (!project || !confirm(`Êtes-vous sûr de vouloir supprimer le projet ${project.name} ?`)) {
        return
    }

    router.delete(route('project.delete', { ulid }), {
        preserveScroll: false,
        preserveState: false,
        only: ['projects', 'flash'],
        onSuccess: function(page) {
            const { projects } = page.props
            projectStore.projects = projects
            closeDialog()
        }
    })
}

// virtual keyboard height hack
const hasFocus = ref(false)
function handleInputFocus() {
    hasFocus.value = true
}
function handleInputBlur() {
    hasFocus.value = false
}
const virtualKeyboardHeight = computed(() => {
    return hasFocus.value && window.visualViewport?.height
        ? window.innerHeight - window.visualViewport.height
        : 0
})
</script>

<template>
    <slot name="newProjectButton" :onClick="openNewProjectSheet" />

    <TransitionRoot as="template" :show="isOpen">
        <Dialog :initialFocus="inputName" @close="closeDialog()" class="relative z-50">
            <!-- The backdrop, rendered as a fixed sibling to the panel container -->
            <TransitionChild
                    as="template"
                    enter="ease-out duration-200"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-light-900/20 dark:bg-light-100/20 backdrop-blur-sm backdrop-brightness-75" aria-hidden="true" />
            </TransitionChild>

            <TransitionChild
                as="template"
                enter="ease-out duration-300"
                enter-from="opacity-0 translate-y-full md:translate-y-0 md:scale-95"
                enter-to="opacity-100 translate-y-0 md:scale-100"
                leave="ease-in duration-200"
                leave-from="opacity-100 translate-y-0 md:scale-100"
                leave-to="opacity-0 translate-y-full md:translate-y-0 md:scale-95"
            >
                <div class="fixed inset-x-0 bottom-0 md:bottom-auto flex flex-col items-center justify-center"
                     :class="{'top-12 md:top-36': detent === 'large', 'top-1/4 md:top-36': detent === 'medium'}"
                >
                    <DialogPanel class="flex-1 flex flex-col w-full md:max-w-sm md:min-h-0 md:max-h-[60vh]"
                                 @keydown.stop
                    >
                        <div class="flex-1 bg-white dark:bg-dark-900 rounded-t-2xl md:rounded-md shadow-xl">
                            <DialogTitle>
                                <div class="flex items-center justify-between px-4 py-3 sm:px-6 sm:py-3 border-b border-light-100 dark:border-dark-800">
                                    <span class="text-lg md:text-xl font-bold">
                                        {{ editMode ? 'Édition du projet' : 'Nouveau projet' }}
                                    </span>
                                    <button @click="closeDialog"
                                            class="group -mr-2 p-2 text-light-500 rounded-md hover:bg-light-100 dark:hover:bg-dark-800"
                                    >
                                        <CloseIcon class="w-6 h-6 group-hover:text-light-600 dark:group-hover:text-dark-400" />
                                    </button>
                                </div>
                            </DialogTitle>

                            <form @submit.prevent="submitForm" :style="{paddingBottom: `${virtualKeyboardHeight}px`}">
                                <div class="mt-6 px-4 sm:px-6">
                                    <InputLabel for="name"
                                                value="Nom du projet"
                                                class="ml-0.5"
                                    />

                                    <div class="mt-1 rounded-md shadow shadow-light-900/10">
                                        <TextInput
                                            id="name"
                                            v-model="form.name"
                                            ref="inputName"
                                            required
                                            autocomplete="off"
                                            autocorrect="off"
                                            enterkeyhint="done"
                                            @focus="handleInputFocus"
                                            @input="form.clearErrors('name')"
                                            @blur="handleInputBlur"
                                            class="mt-1 block w-full"
                                        />
                                    </div>

                                    <InputError :message="form.errors.name" class="mt-1.5 ml-0.5" />
                                </div>

                                <div class="mt-6 px-4 sm:px-6" v-if="!editMode">
                                    <InputLabel for="member_role"
                                                value="Quel est votre rôle dans ce projet ?"
                                                class="ml-0.5"
                                    />
                                    <div class="mt-1">
                                        <MemberRoleSelector
                                                v-model:role="form.member_role"
                                                @update:role="() => form.clearErrors('member_role')"
                                                placeholder="Sélectionner"
                                                class="flex justify-between w-full"
                                        />
                                    </div>
                                    <InputError :message="form.errors.member_role" class="mt-1.5 ml-0.5" />
                                </div>

                                <div class="mt-6 px-4 sm:px-6">
                                    <InputLabel for="client_name"
                                                value="Nom du client"
                                                class="ml-0.5"
                                                optional
                                    />
                                    <div class="mt-1 rounded-md shadow shadow-light-900/10">
                                        <TextInput
                                                id="client_name"
                                                v-model="form.client_name"
                                                autocomplete="off"
                                                autocorrect="off"
                                                enterkeyhint="next"
                                                @focus="handleInputFocus"
                                                @input="form.clearErrors('client_name')"
                                                @blur="handleInputBlur"
                                                class="mt-1 block w-full"
                                        />
                                    </div>
                                    <InputError :message="form.errors.client_name" class="mt-1.5 ml-0.5" />
                                </div>

                                <div class="mt-4 flex p-4 sm:px-6 justify-end">
                                    <button
                                        v-if="editMode"
                                        type="button"
                                        @click="deleteProject"
                                        class="flex items-center gap-x-1 text-sm text-rose-600"
                                    >
                                        <TrashIcon class="w-4 h-4" />
                                        <span class="">supprimer</span>
                                    </button>
                                    <div class="flex-1"></div>
                                    <button
                                        type="submit"
                                        class="w-full md:w-auto bg-accent-400 disabled:bg-light-200/70 p-3 md:py-2 rounded text-sm font-semibold text-accent-950 disabled:text-light-500"
                                    >
                                        {{ editMode ? 'Enregistrer' : 'Créer le projet' }}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </DialogPanel>
                </div>
            </TransitionChild>
        </Dialog>
    </TransitionRoot>
</template>

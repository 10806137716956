<script setup>
import InputError from '@/Components/InputError.vue'
import { PlusIcon as AddMediaIcon } from '@heroicons/vue/24/outline'
import { PaperAirplaneIcon as SendIcon } from '@heroicons/vue/24/solid'
import { useForm } from '@inertiajs/vue3'
import { useTextareaAutosize } from '@vueuse/core'
import { toRef } from 'vue'

const { room } = defineProps({
    room: {
        type: Object,
        required: true
    }
})

const form = useForm({
    content: ''
})
const { textarea, input } = useTextareaAutosize({
    input: toRef(form, 'content')
})

function sendMessage() {
    form
        .transform(data => ({
            ...data
        }))
        .post(route('room.messages.create', {id: room.id}), {
            only: ['project', 'errors'],
            onSuccess: () => {
                form.reset()
            },
        })
}

function handleEnterKey(event) {
    if (!event.shiftKey) {
        event.preventDefault()
        sendMessage()
    }
}
</script>

<template>
    <footer class="py-2.5 standalone:pb-[calc(env(safe-area-inset-bottom)-12px)] lg:standalone:pb-2.5 bg-light-100 dark:bg-dark-900 lg:bg-transparent border-t border-light-900/10 dark:border-dark-100/10">
        <InputError :message="form.errors.content" class="px-4 mb-2" />
        <form
            @submit.prevent="sendMessage"
            class="flex items-end <!--pl-1--> pl-2 pr-2"
        >
            <button
                type="button"
                class="hidden shrink-0 p-1 text-action-500"
            >
                <AddMediaIcon class="w-7 h-7" />
            </button>
            <textarea
                ref="textarea"
                v-model="input"
                @keydown.enter="handleEnterKey"
                @focusin="form.clearErrors('content')"
                @change="form.clearErrors('content')"
                placeholder="Saisissez votre message"
                class="flex-1 max-h-48 px-2.5 py-1.5 lg:py-2.5 dark:bg-dark-800 dark:text-dark-200 dark:font-light dark:placeholder-dark-500 mr-2 rounded-2xl border-0 focus:outline-none focus:ring-0 lg:text-sm resize-none"
            />
            <button
                type="submit"
                :disabled="!input.trim().length"
                class="shrink-0 p-2 rounded-full bg-action-500 text-action-50 disabled:bg-light-300 dark:disabled:bg-dark-600 dark:disabled:text-dark-400"
            >
                <SendIcon class="w-5 h-5" />
            </button>
        </form>
    </footer>
</template>

<script setup lang="ts">
import { useRoomStore } from '@/Stores/RoomStore'
import { ChevronLeftIcon as BackIcon } from '@heroicons/vue/24/outline'
import { UserGroupIcon, UserIcon, UsersIcon } from '@heroicons/vue/24/solid'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

interface Props {
    close: Function
}

defineProps<Props>()

const roomStore = useRoomStore()
const { active: room } = storeToRefs(roomStore)

const roomAvatar = computed(() => {
    const otherMembersCount = room.value ? room.value.members.length - 1 : 0
    switch (otherMembersCount) {
        case 1:
            return UserIcon;
        case 2:
            return UsersIcon;
        default:
            return UserGroupIcon;
    }
})
</script>

<template>
    <header class="flex items-center standalone:pt-[env(safe-area-inset-top)] bg-light-100 dark:bg-dark-900 lg:dark:bg-transparent standalone:bg-gradient-to-b from-light-200 to-light-100 dark:standalone:bg-none lg:bg-none lg:h-14 border-b border-light-900/10 dark:border-dark-100/10">
        <button
            @click="() => close()"
            class="shrink-0 p-2.5 text-action-500"
        >
            <BackIcon class="w-7 h-7 stroke-[2.5] lg:stroke-2 -ml-0.5 mr-0.5" />
        </button>
        <div class="flex-1 pl-8 lg:pl-2 flex items-center">
            <div class="bg-light-400 dark:bg-dark-500 mr-3 aspect-1 rounded-full shrink-0 w-9 h-9 text-light-100 p-1.5">
                <component :is="roomAvatar" class="w-full h-full" />
            </div>
            <div class="flex-1 flex flex-col overflow-x-hidden">
                <h2 class="flex-1 text-base leading-[18px] font-semibold text-light-900 dark:text-dark-100 truncate">{{ room?.name }}</h2>
                <p class="hidden text-xs/4 text-light-800/70 pr-7 text-wrap-balance line-clamp-2">
                    Infos de la room
                </p>
            </div>
        </div>
    </header>
</template>

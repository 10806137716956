<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps<{
    modelValue: string | null,
}>();

defineEmits(['update:modelValue']);

const input = ref<HTMLInputElement | null>(null);

onMounted(() => {
    if (input.value?.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value?.focus() });
</script>

<template>
    <input
        class="border-none ring-1 invalid:ring-1 ring-light-950/10 dark:ring-white/10 dark:bg-dark-400/5 dark:text-dark-300 focus:border-blue-500 dark:focus:border-blue-600 focus:ring-2 focus:ring-blue-500 dark:focus:ring-blue-600 rounded-md shadow-sm shadow-light-900/5"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        ref="input"
    />
</template>

<script setup lang="ts">
import RoomsList from '@/Shared/Room/RoomsList.vue'
import RoomView from '@/Shared/Room/RoomView.vue'
import { useProjectStore } from '@/Stores/ProjectStore'
import { useRoomStore } from '@/Stores/RoomStore'
import { storeToRefs } from 'pinia'

const projectStore = useProjectStore()
const roomStore = useRoomStore()
const { hasActive: hasActiveRoom } = storeToRefs(roomStore)
</script>

<template>
    <div
        v-if="projectStore.active"
        class="bg-light-100/95 dark:bg-dark-950 dark:lg:bg-dark-800/[.99] backdrop-blur-sm sm:ring-1 ring-light-200 dark:ring-dark-800 shadow-lg xl:bg-transparent xl:ring-0 xl:shadow-none flex flex-col"
    >
        <Transition name="toggle-room">
            <RoomView v-if="hasActiveRoom" />
        </Transition>
        <Transition name="toggle-list">
            <RoomsList v-if="!hasActiveRoom" />
        </Transition>
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style>
/* ToggleRoom entering from the right, leaving to the right */
.toggle-room-enter-active {
    transition: transform 250ms ease-in-out ;
    transform-origin: left;
}
.toggle-room-leave-active {
    transition: all 300ms ease-in-out;
    transform-origin: top;
}

.toggle-room-enter-from,
.toggle-room-leave-to {
    transform: translateX(100%);
}

/* ToggleList leaving to the left, re-entering from the left */
.toggle-list-enter-active {
    position: absolute;
    width: 100%;
    transition: all 300ms ease-out 100ms;
}
.toggle-list-leave-active {
    position: absolute;
    width: 100%;
    transition: all 200ms ease-in-out;
}

.toggle-list-enter-from,
.toggle-list-leave-to {
    opacity: 0;
    transform: translateX(-50%);
}
@media (min-width: 640px) {
    .toggle-list-enter-from,
    .toggle-list-leave-to {
        transform: translateX(0);
    }
}
</style>

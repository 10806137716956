import { usePage } from '@inertiajs/vue3'

export function useAuthorisation() {
    const { user, permissions = {}, roles = [] } = usePage().props.auth;

    const userCan = (permission: string): boolean => {
        return permissions[`can_${permission}`] ?? false;
    }

    const userHasRole = (role: string): boolean => {
        return roles.includes(role);
    }

    return {
        user,
        userCan,
        userHasRole
    }
}

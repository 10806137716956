<script lang="ts" setup>
import TextInput from '@/Components/TextInput.vue'
import InputLabel from '@/Components/InputLabel.vue'
import { useForm } from '@inertiajs/vue3'
import InputError from '@/Components/InputError.vue'
import LoaderIcon from '@/Icons/LoaderIcon.vue'
import PrimaryButton from '@/Components/PrimaryButton.vue'
import Checkbox from '@/Components/form/Checkbox.vue'

type User = App.Data.UserData
interface Props {
    user?: User | null
}

const { user } = defineProps<Props>()

const emit = defineEmits(['success'])

const form = useForm({
    user_id: user?.id ?? '',
    first_name: user?.first_name ?? '',
    last_name: user?.last_name ?? '',
    email: user?.email ?? '',
    job: '',
    company: '',
    accept_terms: false,
})
function register() {
    form
        .transform((data) => {
            if (user?.id) {
                // force email to be the same as the user's email
                data.email = user.email
            }
            return data
        })
        .post(route('invitation-request.store'), {
            preserveScroll: true,
            only: ['errors', 'flash'],
            onSuccess: () => {
                emit('success')
                form.reset()
            },
        })
}
</script>

<template>
    <form @submit.prevent="register">
        <div class="grid grid-cols-2 gap-6">
            <div v-if="!user?.id">
                <InputLabel for="first_name" value="Prénom" class="ml-0.5" />
                <div class="mt-1">
                    <TextInput
                            id="first_name"
                            type="text"
                            class="block w-full disabled:bg-light-50 disabled:text-light-500"
                            v-model="form.first_name"
                    />
                </div>
                <InputError class="mt-2" :message="form.errors.first_name" />
            </div>

            <div v-if="!user?.id">
                <InputLabel for="last_name" value="Nom" class="ml-0.5" />
                <div class="mt-1">
                    <TextInput
                            id="last_name"
                            type="text"
                            class="block w-full disabled:bg-light-50 disabled:text-light-500"
                            v-model="form.last_name"
                    />
                </div>
                <InputError class="mt-2" :message="form.errors.last_name" />
            </div>

            <div class="col-span-full">
                <InputLabel for="email" value="Email" class="ml-0.5" />
                <div class="mt-1">
                    <TextInput
                            id="email"
                            type="email"
                            class="block w-full disabled:bg-light-50 disabled:text-light-500"
                            v-model="form.email"
                            :disabled="user?.id"
                    />
                </div>
                <InputError class="mt-2" :message="form.errors.email" />
            </div>

            <div class="col-span-full">
                <InputLabel for="job" value="Votre métier" class="ml-0.5" />
                <div class="mt-1">
                    <TextInput
                        id="job"
                        type="text"
                        class="block w-full"
                        v-model="form.job"
                    />
                </div>
                <InputError class="mt-2" :message="form.errors.job" />
            </div>

            <div class="col-span-full">
                <InputLabel for="company" value="Votre entreprise" class="ml-0.5" />
                <div class="mt-1">
                    <TextInput
                        id="company"
                        type="text"
                        class="block w-full"
                        v-model="form.company"
                    />
                </div>
                <InputError class="mt-2" :message="form.errors.company" />
            </div>

            <div class="col-span-full flex gap-2">
                <Checkbox id="cgu" v-model:checked="form.accept_terms" class="mt-0.5" />
                <div class="">
                    <InputLabel for="cgu">
                        J'ai pris connaissance des présentes
                        <a href="https://wiroom.io/cgu/" target="_blank" class="underline">CGU</a>
                        que j'accepte expressément
                    </InputLabel>
                    <InputError class="mt-2" :message="form.errors.accept_terms ?? null" />
                </div>
            </div>

            <div class="col-span-full flex items-center justify-center sm:justify-end gap-x-6">
                <PrimaryButton
                    type="submit"
                    :disabled="form.processing"
                    class="w-full py-3 justify-center gap-x-2 disabled:bg-opacity-80"
                >
                    <LoaderIcon v-if="form.processing" class="w-4 h-4 animate-spin" />
                    <span v-if="form.processing">Enregistrement</span>
                    <span v-else>Je veux tester WiRoom</span>
                </PrimaryButton>
            </div>
        </div>
    </form>
</template>

<script setup lang="ts">
import MemberSelection from '@/Shared/Room/MemberSelection.vue'
import { useProjectStore } from '@/Stores/ProjectStore'
import { UserCircleIcon as MemberIcon } from '@heroicons/vue/20/solid'
import { useForm } from '@inertiajs/vue3'
import { watchDeep } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { computed, ref, unref } from 'vue'
import {useRoomStore} from '@/Stores/RoomStore'
import InputError from '@/Components/InputError.vue'

type ProjectMember = App.Data.ProjectMemberData
type RoomData = App.Data.RoomData

interface Props {
    availableMembers: ProjectMember[]
}

defineProps<Props>()

const emit = defineEmits(['cancel', 'success'])

const projectStore = useProjectStore()
const { active: project } = storeToRefs(projectStore)

interface SelectedMember {
    user_id: number | null
    name: string
}

const selectedMembers = ref<SelectedMember[]>([])

function toggleMember(projectMember: ProjectMember) {
    const memberIndex = selectedMembers.value.findIndex(m => m.user_id === projectMember.user_id)
    if (memberIndex > -1) {
        selectedMembers.value.splice(memberIndex, 1)
    } else {
        const selectedMember = {
            user_id: projectMember.user_id,
            name: projectMember.user!.first_name,
        }
        selectedMembers.value.push(selectedMember)
    }
}

function removeMember(userId: number) {
    const memberIndex = selectedMembers.value.findIndex(m => m.user_id === userId)
    if (memberIndex > -1) {
        selectedMembers.value.splice(memberIndex, 1)
    }
}

function isMemberSelected(projectMember: ProjectMember) {
    return selectedMembers.value.findIndex(m => m.user_id === projectMember.user_id) > -1
}

const step = ref(1)

const form = useForm({
    room_name: '',
    user_ids: [] as number[],
})

// automatically set room name when members are selected
watchDeep(selectedMembers, (selectedMembers) => {
    if (selectedMembers.length === 1) {
      form.room_name = selectedMembers[0].name
    } else if (selectedMembers.length === 2) {
      form.room_name = selectedMembers.map(m => m.name).join(' & ')
    } else {
      form.room_name = ''
    }
    // reset step when empty
    if (!selectedMembers.length && step.value === 2) {
        // wait for transition to finish
        setTimeout(() => {
            step.value = 1
        }, 300)
    }
})

const isRoomValid = computed(() => {
    return [1,2].includes(selectedMembers.value.length) || form.room_name && selectedMembers.value.length
})

function createRoom() {
    form
        .transform(data => ({
            ...data,
            room_name: selectedMembers.value.length > 2 ? data.room_name.trim() : '',
            user_ids: selectedMembers.value.map(m => m.user_id)
        }))
        .post(route('project.rooms.create', { ulid: unref(project)!.ulid}), {
            only: ['rooms', 'errors', 'flash'],
            onSuccess: page => {
                useRoomStore().rooms = <RoomData[]>page.props.rooms
                reset()
                emit('success')
            }
        })
}

function reset() {
    form.reset()
    step.value = 1
    selectedMembers.value.length = 0
}

function cancel() {
    reset()
    emit('cancel')
}
</script>

<template>
    <form
        @submit.prevent="createRoom"
        class="max-h-full rounded-lg bg-white/70 dark:bg-dark-800 shadow-sm"
    >
        <header
            class="pt-4 pb-2 ml-3 mb-2.5 font-semibold text-light-700 dark:text-dark-300"
            :class="[step === 1 ? 'pl-14' : 'text-center']"
        >
            {{ step === 1 ? 'Sélectionner des membres' : 'Nouvelle room' }}
        </header>

        <div
            v-if="step === 2"
            class="px-3 pb-4"
        >
            <input
                type="text"
                v-model="form.room_name"
                autocomplete="off"
                autocorrect="off"
                spellcheck="false"
                class="w-full border-transparent ring-1 ring-light-950/10 rounded shadow dark:shadow-none shadow-light-200 dark:bg-dark-700 dark:text-dark-200 dark:placeholder:text-dark-400 read-only:bg-light-50 dark:read-only:dark:bg-dark-700 read-only:focus:outline-none read-only:focus:ring-0 disabled:cursor-not-allowed"
                placeholder="Nom de la room"
                :disabled="selectedMembers.length <= 2"
            >
            <InputError class="mt-2" :message="form.errors.room_name ?? null" />

            <p class="mt-4 text-sm font-semibold text-light-600">Membres</p>
            <InputError class="mt-2" :message="form.errors.user_ids ?? null" />
        </div>

        <Transition name="height">
            <MemberSelection
                v-show="selectedMembers.length"
                :selected-members="selectedMembers"
                :remove-member="removeMember"
                class="mx-3"
            />
        </Transition>

        <ul v-if="step === 1" class="divide-y divide-light-900/5 mt-3">
            <li
                v-for="projectMember in availableMembers" :key="projectMember.id"
                @click="toggleMember(projectMember)"
                class="group flex items-center px-1.5 py-1 cursor-pointer"
            >
                <div class="pr-3">
                    <MemberIcon class="shrink-0 w-12 h-12 text-light-400 dark:text-dark-500 group-hover:text-light-500 dark:group-hover:text-dark-400 transition duration-300" />
                </div>
                <div class="flex-1">
                    <span class="block font-medium">{{ projectMember.user ? projectMember.user.full_name : projectMember.user_email }}</span>
                    <span class="block text-sm text-light-600 dark:text-dark-400">
                        {{ projectMember.role }}
                        <span v-if="!projectMember.user" class="italic text-light-500">(en attente)</span>
                    </span>
                </div>
                <div class="pr-1">
                    <input
                        type="checkbox"
                        :checked="isMemberSelected(projectMember)"
                        class="w-6 h-6 text-light-700 dark:text-dark-600 dark:checked:opacity-60 dark:bg-dark-600 border-light-300 dark:border-dark-800 rounded-full focus:ring-0 focus:ring-offset-0 focus-visible:ring-2 focus-visible:ring-light-500 focus-visible:ring-offset-2"
                    >
                </div>
            </li>
        </ul>

        <footer class="flex flex-row-reverse pt-8 pb-2 px-3 items-center justify-between">
            <button v-if="step === 1" type="button" @click="step = 2" :disabled="!selectedMembers.length"
                class="font-semibold text-light-400 enabled:text-action-600">
                Suivant
            </button>
            <button v-else type="submit" :disabled="!isRoomValid"
                    class="font-semibold text-light-400 enabled:text-action-600">
                Créer
            </button>
            <button v-if="step === 1" type="button" @click="cancel" class="text-action-500">
                Annuler
            </button>
            <button v-else type="button" class="text-action-500" @click="step = 1">
                Retour
            </button>
        </footer>
    </form>
</template>

<!--suppress CssUnusedSymbol -->
<style>
.height-enter-active {
    transform-origin: center;
    transition: opacity 300ms ease-out, transform 200ms cubic-bezier(0.3, 0, 0.6, 1);
}
.height-leave-active {
    transform-origin: top;
    transition: opacity 300ms ease-in, transform 200ms cubic-bezier(0.3, 0, 0.6, 1) 300ms;
}

.height-enter-from,
.height-leave-to {
    opacity: 0;
    transform: scaleY(80%);
}
</style>
